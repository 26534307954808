import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '@valedamusica/api';
import InputSwitch from '@valedamusica/components/InputSwitch';
import InputText from '@valedamusica/components/InputText';
import { ISchool } from '@valedamusica/types';

interface ISchoolDialogProps {
  open: boolean;
  onClose: () => void;
  school?: ISchool;
}

interface ISchoolFormData {
  name: string;
  state: string;
  city: string;
  owner: {
    name: string;
    email: string;
  }
  phone: string;
  is_bulk_enabled: boolean;
}

interface ISchoolFormError {
  name?: Array<string>;
  state?: Array<string>;
  city?: Array<string>;
  owner?: {
    name?: Array<string>;
    email?: Array<string>;
  }
  phone?: Array<string>;
  is_bulk_enabled?: Array<string>;
}

const defaultProps = {
  school: undefined,
};

const validationSchema = yup.object({
  name: yup.string().required('Esse campo é obrigatório'),
  state: yup.string().min(2, 'Digite a sigla do estado com 2 letras').max(2, 'Digite a sigla do estado com 2 letras').required('Esse campo é obrigatório'),
  city: yup.string().required('Esse campo é obrigatório'),
  owner: yup.object({
    name: yup.string().required('Esse campo é obrigatório'),
    email: yup.string().email('Email inválido.').required('Esse campo é obrigatório.'),
  }),
  phone: yup.string().required('Esse campo é obrigatório'),
  is_bulk_enabled: yup.bool(),
});

function SchoolDialog({ open, onClose, school }: ISchoolDialogProps) {
  const editMode = Boolean(school);
  const defaultValues = {
    name: '',
    state: '',
    city: '',
    owner: {
      name: '',
      email: '',
    },
    phone: '',
    is_bulk_enabled: false,
  };

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    mode: 'onBlur',
    criteriaMode: 'firstError',
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (!school) return;
    methods.setValue('name', school.name);
    methods.setValue('state', school.state);
    methods.setValue('city', school.city);
    methods.setValue('owner.name', school.owner.name);
    methods.setValue('owner.email', school.owner.email);
    methods.setValue('phone', school.phone);
    methods.setValue('is_bulk_enabled', school.is_bulk_enabled);
  }, [methods, school]);

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = async (formData: ISchoolFormData) => {
    try {
      if (school) {
        await api.patch(`/escolas/${school.pk}/`, formData);
      } else {
        await api.post('/escolas/', formData);
      }
      handleClose();
    } catch (err: any) {
      const error = err.response.data;

      if (!error) return;

      const fieldErrors = error as ISchoolFormError;

      Object.entries(fieldErrors).forEach(([field, value]) => {
        /* eslint-disable indent */
        switch (field) {
          case 'name':
          case 'state':
          case 'city':
          case 'phone':
            methods.setError(field, { message: value.join('. ') });
            break;
          case 'owner':
            if (value.name) methods.setError('owner.name', { message: value.name.join('. ') });
            if (value.email) methods.setError('owner.email', { message: value.email.join('. ') });
            break;
          default:
            break;
        }
        /* eslint-enable indent */
      });
    }
  };

  if (!open) return null;

  let title;
  let description;
  let sendLabel;

  if (school) {
    title = 'Atualizar instituição';
    description = 'Edite os dados desejados no formulário abaixo:';
    sendLabel = 'Atualizar';
  } else {
    title = 'Cadastrar nova instituição';
    description = 'Preencha as informações abaixo para liberar acesso a uma nova instituição:';
    sendLabel = 'Cadastrar';
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormProvider {...methods} /* eslint-disable-line */>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
            <Grid container rowGap={1} mt={2} justifyContent="space-between">
              <Grid item xs={12}>
                <InputText name="name" label="Nome" />
              </Grid>
              <Grid item xs={12} sm={9}>
                <InputText name="city" label="Cidade" />
              </Grid>
              <Grid item xs={12} sm={2}>
                <InputText name="state" label="Estado" />
              </Grid>
              <Grid item xs={12}>
                <InputText name="owner.name" label="Nome do Responsável" disabled={editMode} />
              </Grid>
              <Grid item xs={12}>
                <InputText name="owner.email" label="Email do Responsável" disabled={editMode} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputText name="phone" label="Telefone de Contato" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputSwitch name="is_bulk_enabled" label="Permite emissão em lote?" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="error">Fechar</Button>
            <Button variant="contained" color="primary" type="submit">{sendLabel}</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

SchoolDialog.defaultProps = defaultProps;

export default SchoolDialog;
