import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';

import InputError from '@valedamusica/components/InputError';

interface InputTextProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

const defaultProps = {
  type: 'text',
  required: true,
  disabled: false,
  placeholder: undefined,
};

function InputText({
  name,
  label,
  type,
  required,
  disabled,
  placeholder,
}: InputTextProps) {
  const { field, fieldState } = useController({ name });

  return (
    <>
      <TextField
        type={type}
        name={name}
        label={label}
        error={Boolean(fieldState.error)}
        placeholder={placeholder}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        required={required}
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
          variant: 'standard',
        }}
        fullWidth
        margin="dense"
        variant="standard"

      />
      <InputError error={fieldState.error} />
    </>
  );
}

InputText.defaultProps = defaultProps;

export default InputText;
