import {
  createContext, useCallback, useEffect, useMemo, useState, PropsWithChildren,
} from 'react';
import api from '@valedamusica/api';
import { IUser } from '@valedamusica/types';

export interface IAuth {
  token: string;
  expiry: string;
}

interface IAuthContext {
  auth?: IAuth;
  user?: IUser;
  login(authData: IAuth | undefined): void; // eslint-disable-line no-unused-vars
  logout(): void;
}

export const AuthContext = createContext<IAuthContext>({
  auth: undefined,
  user: undefined,
  login: (authData: IAuth) => { }, // eslint-disable-line no-unused-vars
  logout: () => { },
});

function AuthProvider({ children }: PropsWithChildren) {
  const [auth, setAuth] = useState<IAuth | undefined>(() => {
    const storedAuth = localStorage.getItem('auth');
    return storedAuth ? JSON.parse(storedAuth) : undefined;
  });
  const [user, setUser] = useState<IUser | undefined>(undefined);

  useEffect(() => {
    const fetchUser = async () => {
      const headers = { Authorization: `Token ${auth?.token}` };
      const response = await api.get<IUser>('/me/', { headers });
      setUser(response.data);
    };

    if (!auth) return;

    fetchUser();
  }, [auth]);

  const login = useCallback((authData: IAuth) => {
    localStorage.setItem('auth', JSON.stringify(authData));
    setAuth(authData);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('auth');
    setAuth(undefined);
    setUser(undefined);
  }, []);

  const value = useMemo(() => ({
    auth, user, login, logout,
  }), [auth, user, logout]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
