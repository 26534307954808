import { useState, MouseEvent } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { GridAlignment, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';

import api from '@valedamusica/api';
import Layout from '@valedamusica/components/Layout';
import Table from '@valedamusica/components/Table';
import SchoolDialog from '@valedamusica/pages/schools/SchoolDialog';
import { ISchool } from '@valedamusica/types';

function SchoolList() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentSchool, setCurrentSchool] = useState<ISchool | undefined>(undefined);
  const endpoint = '/escolas/';
  const response = useSWR<AxiosResponse<ISchool[]>>(endpoint, api.get);

  const openDialog = () => setIsDialogOpen(true);

  const closeDialog = () => {
    setIsDialogOpen(false);
    setCurrentSchool(undefined);
    response.mutate();
  };

  const openChangeDialog = (school: ISchool) => {
    setCurrentSchool(school);
    openDialog();
  };

  const rows = response.data?.data ?? [];

  const columns = [
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
    },
    {
      field: 'city',
      headerName: 'Cidade',
      flex: 0.8,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
    },
    {
      field: 'state',
      headerName: 'Estado',
      flex: 0.4,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      flex: 0.7,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
      valueFormatter: ({ value }: { value?: string }) => {
        if (!value) return '';
        const [prefix, phoneNumber] = value.split(' ');
        return `(${prefix}) ${phoneNumber}`;
      },
    },
    {
      field: 'owner.name',
      headerName: 'Responsável',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
      valueGetter: (params: GridValueGetterParams<ISchool>) => params.row.owner.name,
    },
    {
      field: 'owner.email',
      headerName: 'Email',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
      valueGetter: (params: GridValueGetterParams<ISchool, ISchool>) => params.row.owner.email,
    },
    {
      field: 'created_at',
      headerName: 'Criada Em',
      flex: 0.6,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      valueFormatter: ({ value }: { value: string }) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'is_active',
      headerName: 'Ativa?',
      flex: 0.3,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ value }: GridRenderCellParams) => {
        const IconComponent = value ? CheckCircleOutlineIcon : DoNotDisturbIcon;
        return (<Icon><IconComponent /></Icon>);
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      flex: 0.5,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ row }: GridRenderCellParams<ISchool>) => (
        <Button
          variant="contained"
          size="medium"
          onClick={(evt: MouseEvent<HTMLButtonElement>) => {
            evt.preventDefault();
            evt.stopPropagation();
            openChangeDialog(row);
          }}
        >
          Editar
        </Button>
      ),
    },
  ];

  return (
    <Layout>
      <Grid container rowGap={2} flexDirection="column">
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="center">
            Instituições
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={rows}
            columns={columns}
            loading={response.isValidating}
            baseEndpoint="/solicitacoes"
          />
        </Grid>
        <Grid item xs={12} alignSelf="flex-end">
          <Button variant="contained" size="medium" onClick={openDialog}>Cadastrar</Button>
        </Grid>
      </Grid>
      <SchoolDialog open={isDialogOpen} onClose={closeDialog} school={currentSchool} />
    </Layout>
  );
}

export default SchoolList;
