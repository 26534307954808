import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '@valedamusica/api';
import Image from '@valedamusica/components/Image';
import InputText from '@valedamusica/components/InputText';

interface IRequestPasswordProps {
  title: string;
  description: string;
}

interface IRequestPasswordFormData {
  email: string;
}

function RequestPassword({ title, description }: IRequestPasswordProps) {
  const [showAlert, setShowAlert] = useState(false);
  const defaultValues = {
    email: '',
  };

  const validationSchema = yup.object({
    email: yup.string().email('Email inválido').required('Esse campo é obrigatório.'),
  });

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
    resolver,
  });

  const emailWatch = methods.watch('email');

  useEffect(() => {
    if (showAlert) {
      if (emailWatch) {
        setShowAlert(false);
      } else {
        setTimeout(() => setShowAlert(false), 5000);
      }
    }
  }, [showAlert, emailWatch]);

  const onSubmit = async (data: IRequestPasswordFormData) => {
    try {
      await api.post('/solicitar-senha/', data);
      methods.reset();
      setShowAlert(true);
    } catch (err: any) {
      const { data: error } = err.response;

      if (!error) return;

      if (error.non_field_errors) {
        methods.setError('email', { message: '' });
      }
      if (error.email) {
        methods.setError('email', { message: error.email });
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <CssBaseline />
      <Hidden mdDown>
        <Box sx={{
          alignItems: 'center',
          backgroundColor: 'primary.dark',
          backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'repeat',
          display: 'flex',
          justifyContent: 'center',
          padding: 4,
          width: '50%',
        }}
        >
          <Typography variant="h3" color="secondary.main" textAlign="center">Emissão de Cortesias</Typography>
        </Box>
      </Hidden>
      <Container maxWidth="sm" sx={{ height: 'inherit' }}>
        <Grid container rowGap={2} justifyContent="center" alignContent="flex-start" height="inherit">
          <Grid item xs={12}>
            <Link to="/">
              <Image src="/logo.png" alt="Vale da Música" width={546} />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item>
            <FormProvider {...methods} /* eslint-disable-line */>
              <Grid item container component="form" onSubmit={methods.handleSubmit(onSubmit)} rowGap={1}>
                <Grid item xs={12}>
                  <InputText name="email" label="Email" type="text" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button variant="contained" size="medium" type="submit">
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
          <Grid item hidden={!showAlert} my={2}>
            <Alert severity="success" variant="outlined">
              <Typography variant="body2">Pedido de alteração de senha realizado!</Typography>
              <Typography variant="body2">Aguarde alguns minutos e confira seu email</Typography>
            </Alert>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default RequestPassword;
