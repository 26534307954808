import Layout from '@valedamusica/components/Layout';

function NotFound() {
  return (
    <Layout>
      <h1>Página não encontrada!</h1>
    </Layout>
  );
}

export default NotFound;
