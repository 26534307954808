import {
  DataGrid, GridColumns, GridRowsProp,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import gridLocaleText from '@valedamusica/components/gridLocaleText';
import '@valedamusica/components/Table.css';

interface TableProps {
  rows: GridRowsProp;
  columns: GridColumns;
  loading: boolean;
  baseEndpoint?: string;
}

const defaultProps = {
  baseEndpoint: undefined,
};

function Table({
  rows, columns, loading, baseEndpoint,
}: TableProps) {
  const navigate = useNavigate();
  return (
    <DataGrid
      loading={loading}
      rows={rows}
      columns={columns}
      getRowId={(obj) => obj.pk}
      onRowClick={(params) => {
        if (!baseEndpoint) return;
        navigate(`${baseEndpoint}/${params.id}`);
      }}
      autoHeight
      // density="comfortable"
      disableColumnMenu
      disableSelectionOnClick
      editMode="row"
      // getDetailPanelContent={(obj) => {
      //   console.log(obj);
      //   return '';
      // }}
      hideFooterSelectedRowCount
      // keepNonExistentRowsSelected
      localeText={gridLocaleText}
      pageSize={20}

    />
  );
}

Table.defaultProps = defaultProps;

export default Table;
