import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '@valedamusica/api';
import Image from '@valedamusica/components/Image';
import InputText from '@valedamusica/components/InputText';

interface IPasswordRecoveryProps {
  title: string;
  description: string;
}

interface IPasswordRecoveryFormData {
  password: string;
  confirm_password: string;
}

function PasswordRecovery({ title, description }: IPasswordRecoveryProps) {
  const navigate = useNavigate();
  const params = useParams();

  const defaultValues = {
    password: '',
    confirm_password: '',
  };

  const validationSchema = yup.object({
    password: yup.string().required('Esse campo é obrigatório.'),
    confirm_password: yup.string().required('Esse campo é obrigatório.'),
  });

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
    resolver,
  });

  const onSubmit = async (data: IPasswordRecoveryFormData) => {
    try {
      await api.post(`/recuperar-senha/${params.id}/`, data);
      navigate('/login', { replace: true });
    } catch (err: any) {
      const { status, data: error } = err.response;

      if (status === 404) {
        methods.setError('confirm_password', { message: 'Token inválido' });
        return;
      }

      if (!error) return;

      if (error.non_field_errors) {
        methods.setError('password', { message: '' });
        methods.setError('confirm_password', { message: error.non_field_errors });
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <CssBaseline />
      <Hidden mdDown>
        <Box sx={{
          alignItems: 'center',
          backgroundColor: 'primary.dark',
          backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'repeat',
          display: 'flex',
          justifyContent: 'center',
          padding: 4,
          width: '50%',
        }}
        >
          <Typography variant="h3" color="secondary.main" textAlign="center">Emissão de Cortesias</Typography>
        </Box>
      </Hidden>
      <Container maxWidth="sm" sx={{ height: 'inherit' }}>
        <Grid container rowGap={2} justifyContent="center" alignContent="flex-start" height="inherit">
          <Grid item xs={12}>
            <Link to="/">
              <Image src="/logo.png" alt="Vale da Música" width={546} />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center">{description}</Typography>
          </Grid>
          <Grid item>
            <FormProvider {...methods} /* eslint-disable-line */>
              <Grid item container component="form" onSubmit={methods.handleSubmit(onSubmit)} rowGap={1}>
                <Grid item xs={12}>
                  <InputText name="password" label="Senha" type="password" />
                </Grid>
                <Grid item xs={12}>
                  <InputText name="confirm_password" label="Confirmar Senha" type="password" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button variant="contained" size="medium" type="submit">
                    Enviar
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default PasswordRecovery;
