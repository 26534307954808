import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ptBrLocale from 'date-fns/locale/pt-BR';
import { useController, useFormContext } from 'react-hook-form';

import InputError from '@valedamusica/components/InputError';

interface InputDateProps {
  name: string;
  label: string;
  required?: boolean;
  disablePast?: boolean;
}

const defaultProps = {
  required: true,
  disablePast: true,
};

function InputDate({
  name, label, required, disablePast,
}: InputDateProps) {
  const [errorReason, setErrorReason] = useState('');
  const { field, fieldState } = useController({ name });
  const { setError, clearErrors } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBrLocale}>
      <DatePicker
        label={label}
        value={field.value}
        onChange={field.onChange}
        disablePast={disablePast}
        onError={(reason) => {
          if (!reason) {
            setErrorReason('');
            clearErrors(name);
            return;
          }
          setErrorReason(reason);
        }}
        renderInput={(params) => (
          <>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              error={Boolean(fieldState.error)}
              onBlur={() => {
                if (errorReason) {
                  setError(name, { message: 'A data é inválida' });
                } else if (fieldState.error) {
                  clearErrors(name);
                }
              }}
              inputProps={{
                ...params.inputProps,
                placeholder: 'dd/mm/aaaa',
              }}
              required={required}
              InputLabelProps={{
                shrink: true,
                variant: 'standard',
              }}
              fullWidth
              margin="dense"
              variant="standard"
            />
            <InputError error={fieldState.error} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}

InputDate.defaultProps = defaultProps;

export default InputDate;
