import { useContext, useState, MouseEvent } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { GridAlignment, GridRenderCellParams } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';

import api from '@valedamusica/api';
import Layout from '@valedamusica/components/Layout';
import Table from '@valedamusica/components/Table';
import TicketRequestDialog from '@valedamusica/pages/ticketrequests/TicketRequestDialog';
import { AuthContext } from '@valedamusica/providers/AuthProvider';
import { ISchool, ITicketRequest, IUser } from '@valedamusica/types';
import { convertStringToDate } from '@valedamusica/utils';

function TicketRequestList() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const params = useParams();

  let schoolId = null;
  let parentEndpoint = null;
  let endpoint = null;
  if (user?.is_staff) {
    schoolId = params.school;
    parentEndpoint = params.school ? `/escolas/${params.school}/` : null;
    endpoint = params.school ? `/escolas/${params.school}/solicitacoes/` : '/solicitacoes/';
  } else {
    schoolId = user?.school?.toString();
    parentEndpoint = schoolId ? `/escolas/${schoolId}/` : null;
    endpoint = user ? '/solicitacoes/' : null;
  }
  const parentResponse = useSWR<AxiosResponse<ISchool>>(parentEndpoint, api.get);
  const response = useSWR<AxiosResponse<ITicketRequest[]>>(endpoint, api.get);

  const openDialog = () => setIsDialogOpen(true);

  const closeDialog = () => {
    setIsDialogOpen(false);
    response.mutate();
  };

  const approveRequest = async (ticketRequest: ITicketRequest, approved: boolean) => {
    await api.patch(`/solicitacoes/${ticketRequest.pk}/`, {
      is_active: approved,
    });
    response.mutate();
  };

  const rows = response.data?.data ?? [];

  const columns = [
    {
      field: 'pk',
      headerName: 'ID',
      flex: 0.2,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      valueFormatter: ({ value }: { value: number }) => `# ${value}`,
    },
    {
      field: 'created_at',
      headerName: 'Data Solicitação',
      flex: 0.5,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      valueFormatter: ({ value }: { value: string }) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'qty',
      headerName: 'Quantidade',
      flex: 0.4,
      headerAlign: 'center' as GridAlignment,
      align: 'right' as GridAlignment,
    },
    {
      field: 'expiry_date',
      headerName: 'Validade',
      flex: 0.5,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      // eslint-disable-next-line max-len
      valueFormatter: ({ value }: { value: string }) => convertStringToDate(value).toLocaleDateString(),
    },
    {
      field: 'created_by',
      headerName: 'Criada Por',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
      valueFormatter: ({ value }: { value: IUser }) => value.name,
    },
    {
      field: 'is_active',
      headerName: 'Ativa?',
      flex: 0.2,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ value }: GridRenderCellParams) => {
        const IconComponent = value ? CheckCircleOutlineIcon : DoNotDisturbIcon;
        return (<Icon><IconComponent /></Icon>);
      },
    },
  ];

  if (user?.is_staff) {
    if (!schoolId) {
      columns.splice(1, 0, {
        field: 'school_details',
        headerName: 'Instituição',
        flex: 1,
        headerAlign: 'center' as GridAlignment,
        align: 'left' as GridAlignment,
        valueFormatter: ({ value }: { value: IUser }) => value.name,
      });
    }

    columns.push({
      field: 'actions',
      headerName: 'Ações',
      flex: 0.5,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ row }: GridRenderCellParams<ITicketRequest>) => {
        let color: 'primary' | 'secondary';
        let label;
        if (row.is_active) {
          color = 'secondary';
          label = 'Bloquear';
        } else {
          color = 'primary';
          label = 'Aprovar';
        }
        return (
          <Button
            onClick={(evt: MouseEvent<HTMLButtonElement>) => {
              evt.preventDefault();
              evt.stopPropagation();
              approveRequest(row, !row.is_active);
            }}
            variant="contained"
            size="medium"
            color={color}
          >
            {label}

          </Button>
        );
      },
    });
  }

  let title = 'Solicitações de ingressos';
  if (params.school) {
    title = parentResponse.isValidating
      ? '-'
      : `Solicitações para ${parentResponse.data?.data?.name || ''}`;
  }

  return (
    <Layout>
      <Grid container rowGap={2} flexDirection="column">
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="center">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={rows}
            columns={columns}
            loading={response.isValidating}
            baseEndpoint="/ingressos"
          />
        </Grid>
        <Grid item xs={12} alignSelf="flex-end">
          <Button variant="contained" size="medium" onClick={openDialog}>Criar Solicitação</Button>
        </Grid>
      </Grid>
      <TicketRequestDialog open={isDialogOpen} onClose={closeDialog} school={schoolId} />
    </Layout>
  );
}

export default TicketRequestList;
