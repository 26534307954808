interface ImageProps {
  src: string;
  alt: string;
  width: number;
}

export default function Image({ src, alt, width }: ImageProps) {
  const imgSrc = process.env.REACT_APP_ASSETS_FOLDER ? `${process.env.REACT_APP_ASSETS_FOLDER}/imgs${src}` : `/imgs${src}`;

  return (
    <img src={imgSrc} alt={alt} width={width} />
  );
}
