import { PropsWithChildren } from 'react';
import { ThemeProvider as Provider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#333333',
      light: '#424242',
      dark: '#1d1d1b',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#e6e6e6',
      light: '#ffffff',
      dark: '#cccccc',
      contrastText: '#7b7b7b',
    },
    info: {
      main: '#71caf3',
      light: '#a1dcf7',
      dark: '#43b9ef',
      contrastText: '#1a1a1a',
    },
    success: {
      main: '#b5ce4f',
      light: '#c6d978',
      dark: '#9db734',
      contrastText: '#333333',
    },
    error: {
      main: '#e63331',
      light: '#eb6260',
      dark: '#cc1c19',
      contrastText: '#fafafa',
    },
    text: {
      primary: '#1d1d1b',
      secondary: '#7b7b7b',
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Arial", "sans-serif"',
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '0.8em',
        },
      },
    },
  },
});

// eslint-disable-next-line max-len
function ThemeProvider({ children }: PropsWithChildren) {
  return <Provider theme={theme}>{children}</Provider>;
}

export default ThemeProvider;
