import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { useController } from 'react-hook-form';

import InputError from '@valedamusica/components/InputError';
import { ISelectOption } from '@valedamusica/types';

interface InputSelectProps {
  name: string;
  label: string;
  options: Array<ISelectOption>;
  required?: boolean;
  disabled?: boolean;
}

const defaultProps = {
  required: true,
  disabled: false,
};

function InputSelect({
  name,
  label,
  options,
  required,
  disabled,
}: InputSelectProps) {
  const { field, fieldState } = useController({ name });

  return (
    <>
      <TextField
        name={name}
        label={label}
        select
        error={Boolean(fieldState.error)}
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value}
        required={required}
        InputLabelProps={{
          shrink: true,
          variant: 'standard',
        }}
        fullWidth
        margin="dense"
        variant="standard"
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <InputError error={fieldState.error} />
    </>
  );
}

InputSelect.defaultProps = defaultProps;

export default InputSelect;
