import FormHelperText from '@mui/material/FormHelperText';
import { FieldError } from 'react-hook-form';

interface InputErrorProps {
  error?: FieldError;
}

const defaultProps = {
  error: undefined,
};

function InputError({ error }: InputErrorProps) {
  if (!error) return <FormHelperText sx={{ minHeight: 'calc(1.5em + 2px)' }} />;

  if (error.types !== undefined) {
    return (
      <FormHelperText error>
        {Object.values(error.types)
          .map((errorObj) => errorObj)
          .join('. ')}
      </FormHelperText>
    );
  }

  return <FormHelperText error sx={{ minHeight: 'calc(1.5em + 2px)' }}>{error.message}</FormHelperText>;
}

InputError.defaultProps = defaultProps;

export default InputError;
