import { useEffect, useRef, useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { GridAlignment } from '@mui/x-data-grid';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';
import { FormProvider, useForm } from 'react-hook-form';

import api from '@valedamusica/api';
import InputDate from '@valedamusica/components/InputDate';
import InputSwitch from '@valedamusica/components/InputSwitch';
import Layout from '@valedamusica/components/Layout';
import Table from '@valedamusica/components/Table';
import { ISummary } from '@valedamusica/types';
import { formatDate } from '@valedamusica/utils';

interface ISummaryReportFormData {
  startDate: string;
  endDate: string;
  issuedOnly: boolean;
}

function Summary() {
  const baseEndpoint = '/resumo';
  const [endpoint, setEndpoint] = useState(baseEndpoint);
  const [currentFilter, setCurrentFilter] = useState({ startDate: '', endDate: '', issuedOnly: false });

  const defaultValues = {
    startDate: '',
    endDate: '',
    issuedOnly: false,
  };
  const linkRef = useRef<HTMLAnchorElement>(null);

  const methods = useForm({
    mode: 'onBlur',
    criteriaMode: 'firstError',
    defaultValues,
  });

  const queryUrl = endpoint === baseEndpoint ? null : endpoint;
  const watchValues = methods.watch();
  const { data: response, isValidating } = useSWR<AxiosResponse<ISummary[]>>(queryUrl, api.get);

  const onSubmit = (formData: ISummaryReportFormData) => {
    const url = new URL(baseEndpoint, api.defaults.baseURL);
    const startDate = formatDate(formData.startDate);
    const endDate = formatDate(formData.endDate);
    url.searchParams.append('start_date', startDate);
    url.searchParams.append('end_date', endDate);
    url.searchParams.append('issued_only', formData.issuedOnly.toString());
    setEndpoint(`${baseEndpoint}/${url.search}`);
    setCurrentFilter(formData);
  };

  const downloadReport = async () => {
    const link = linkRef.current;
    if (!link) return;

    const urlReport = new URL('/resumo/download', api.defaults.baseURL);
    const startDate = formatDate(methods.getValues('startDate'));
    const endDate = formatDate(methods.getValues('endDate'));
    const issuedOnly = methods.getValues('issuedOnly').toString();
    urlReport.searchParams.append('start_date', startDate);
    urlReport.searchParams.append('end_date', endDate);
    urlReport.searchParams.append('issued_only', issuedOnly);
    const endpointReport = `/resumo/download/${urlReport.search}`;
    const { data: blob } = await api.get(endpointReport, { responseType: 'blob' });
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.download = `relatorio-${startDate}-${endDate}.xlsx`;
    link.click();
  };

  useEffect(() => {
    const hasChanges = (
      watchValues.startDate.toString() !== currentFilter.startDate.toString()
      || watchValues.endDate.toString() !== currentFilter.endDate.toString()
      || watchValues.issuedOnly !== currentFilter.issuedOnly
    );
    if (hasChanges && endpoint) {
      setEndpoint(baseEndpoint);
    }
  }, [watchValues, currentFilter, endpoint, baseEndpoint]);

  const rows = response?.data ?? [];

  const columns = [
    {
      field: 'school',
      headerName: 'Instituição',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
    },
    {
      field: 'owner_name',
      headerName: 'Responsável',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
    },
    {
      field: 'issued_tickets',
      headerName: 'Ingressos Disponibilizados',
      flex: 0.7,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
    },
  ];

  if (!watchValues.issuedOnly) {
    columns.push({
      field: 'used_tickets',
      headerName: 'Ingressos Validados',
      flex: 0.7,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
    });
  }

  return (
    <Layout>
      <Grid container rowGap={2} flexDirection="column">
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="center">
            Relatório de Ingressos
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormProvider {...methods} /* eslint-disable-line */>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container columnGap={4} justifyContent="center" alignItems="center">
                <Grid item>
                  <InputDate name="startDate" label="Data Inicial" disablePast={false} />
                </Grid>
                <Grid item>
                  <InputDate name="endDate" label="Data Inicial" disablePast={false} />
                </Grid>
                <Grid item>
                  <InputSwitch name="issuedOnly" label="Apenas Disponbilizados?" />
                </Grid>
              </Grid>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Button variant="contained" size="medium" type="submit">Filtrar</Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Grid>
        <Grid item xs={12}>
          {methods.formState.submitCount > 0 && !queryUrl ? (
            <Alert severity="info" variant="outlined">
              <Typography variant="body2">Os filtros foram alterados!</Typography>
              <Typography variant="body2">
                Clique no botão
                <strong> Filtrar </strong>
                novamente para carregar os dados atualizados.
              </Typography>
            </Alert>
          ) : (
            <Table
              rows={rows}
              columns={columns}
              loading={isValidating}
            />
          )}
        </Grid>
        <Grid item xs={12} alignSelf="flex-end">
          <Button
            variant="contained"
            size="medium"
            onClick={downloadReport}
            disabled={!rows.length}
          >
            Baixar
          </Button>
          <Hidden>
            <a ref={linkRef} /* eslint-disable-line */ />
          </Hidden>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default Summary;
