import { useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import { useNavigate, Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '@valedamusica/api';
import Image from '@valedamusica/components/Image';
import InputText from '@valedamusica/components/InputText';
import { AuthContext, IAuth } from '@valedamusica/providers/AuthProvider';

interface ILoginFormData {
  username: string;
  password: string;
}

function Login() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const defaultValues = {
    username: '',
    password: '',
  };

  const validationSchema = yup.object({
    username: yup.string().email('Email inválido.').required('Esse campo é obrigatório.'),
    password: yup.string().required('Esse campo é obrigatório.'),
  });

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    mode: 'onBlur',
    criteriaMode: 'all',
    defaultValues,
    resolver,
  });

  const onSubmit = async (data: ILoginFormData) => {
    try {
      const response = await api.post<IAuth>('/login/', data);
      login(response.data);
      navigate('/', { replace: true });
    } catch (err: any) {
      const error = err.response.data;

      if (!error) return;

      if (error.non_field_errors) {
        methods.setError('username', { message: '' });
        methods.setError('password', { message: error.non_field_errors });
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', height: '100vh' }}>
      <CssBaseline />
      <Hidden mdDown>
        <Box sx={{
          alignItems: 'center',
          backgroundColor: 'primary.dark',
          backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
          backgroundPosition: 'center center',
          backgroundRepeat: 'repeat',
          display: 'flex',
          justifyContent: 'center',
          padding: 4,
          width: '50%',
        }}
        >
          <Typography variant="h3" color="secondary.main" textAlign="center">Emissão de Cortesias</Typography>
        </Box>
      </Hidden>
      <Container maxWidth="sm" sx={{ height: 'inherit' }}>
        <Grid container rowGap={2} justifyContent="center" alignContent="flex-start" height="inherit">
          <Grid item xs={12}>
            <Link to="/">
              <Image src="/logo.png" alt="Vale da Música" width={546} />
            </Link>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">Login</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormProvider {...methods} /* eslint-disable-line */>
              <Grid item container component="form" onSubmit={methods.handleSubmit(onSubmit)} rowGap={1}>
                <Grid item xs={12}>
                  <InputText name="username" label="Email" />
                </Grid>
                <Grid item xs={12}>
                  <InputText name="password" label="Senha" type="password" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button variant="contained" size="medium" type="submit">
                    Entrar
                  </Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Grid>
          <Grid item xs={12} sm={8} my={4}>
            <Link to="/esqueci-minha-senha">
              <Typography textAlign="center" color="secondary.contrastText">Esqueci minha senha</Typography>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Login;
