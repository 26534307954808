import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '@valedamusica/api';
import InputSelect from '@valedamusica/components/InputSelect';
import InputText from '@valedamusica/components/InputText';
import useApiFormOptions from '@valedamusica/hooks/useApiFormOptions';

interface ITicketDialogBulkProps {
  open: boolean;
  onClose: () => void;
  request?: string;
}

interface ITicketBulkFormData {
  request: string;
  qty: number;
}

interface ITicketBulkFormError {
  request?: Array<string>;
  qty?: Array<string>;
}

const defaultProps = {
  request: null,
};

const validationSchema = yup.object({
  request: yup.string().ensure().required(),
  qty: yup.number().min(1, 'Informe um valor maior do que zero').required(),
});

function TicketRequestDialogBulk({ open, onClose, request }: ITicketDialogBulkProps) {
  const requestOptions = useApiFormOptions('/solicitacoes/form-options/');
  const defaultValues = {
    request: '',
    qty: 0,
  };

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    mode: 'onBlur',
    criteriaMode: 'firstError',
    defaultValues,
    resolver,
  });
  const {
    setValue, reset, handleSubmit, formState,
  } = methods;

  useEffect(() => {
    if (!request) return;
    setValue('request', request);
  }, [setValue, request]);

  const handleClose = () => {
    reset();
    if (request) {
      setValue('request', request);
    }
    onClose();
  };

  const onSubmit = async (formData: ITicketBulkFormData) => {
    try {
      await api.post('/ingressos/criar-em-lote/', formData);
      handleClose();
    } catch (err: any) {
      const error = err.response.data;

      if (!error) return;

      const fieldErrors = error as ITicketBulkFormError;

      Object.entries(fieldErrors).forEach(([field, value]) => {
        /* eslint-disable indent */
        switch (field) {
          case 'request':
          case 'qty':
            methods.setError(field, { message: value.join('. ') });
            break;
          default:
            break;
        }
        /* eslint-enable indent */
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormProvider {...methods} /* eslint-disable-line */>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Emitir cortesias em lote</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Informe a quantidade de cortesias que deseja emitir.
            </DialogContentText>
            <Grid container rowGap={1} mt={2}>
              <Grid item xs={12}>
                <InputSelect
                  name="request"
                  label="Solicitação"
                  options={requestOptions}
                  disabled={Boolean(request)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputText name="qty" label="Quantidade" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="contained"
              color="error"
              disabled={formState.isSubmitting}
            >
              Fechar
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<SendIcon />}
              loading={formState.isSubmitting}
              loadingPosition="start"
            >
              Emitir
            </LoadingButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

TicketRequestDialogBulk.defaultProps = defaultProps;

export default TicketRequestDialogBulk;
