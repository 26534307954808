import Layout from '@valedamusica/components/Layout';

function Home() {
  return (
    <Layout>
      <h1>Home</h1>
    </Layout>
  );
}

export default Home;
