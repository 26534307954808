import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useController } from 'react-hook-form';

interface InputSwitchProps {
  name: string;
  label: string;
}

function InputSwitch({ name, label }: InputSwitchProps) {
  const { field } = useController({ name });

  return (
    <FormControl size="small" fullWidth>
      <FormControlLabel
        control={<Switch checked={field.value} onChange={field.onChange} />}
        label={label}
        labelPlacement="bottom"
      />
    </FormControl>
  );
}

export default InputSwitch;
