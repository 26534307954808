import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import api from '@valedamusica/api';
import InputDate from '@valedamusica/components/InputDate';
import InputSelect from '@valedamusica/components/InputSelect';
import InputText from '@valedamusica/components/InputText';
import useApiFormOptions from '@valedamusica/hooks/useApiFormOptions';

interface ITicketRequestDialogProps {
  open: boolean;
  onClose: () => void;
  school?: string;
}

interface ITicketRequestFormData {
  school: string;
  qty: string;
  expiry_date: string;
}

interface ITicketRequestFormError {
  school?: Array<string>;
  qty?: Array<string>;
  expiry_date?: Array<string>;
}

const defaultProps = {
  school: null,
};

const validationSchema = yup.object({
  qty: yup.number().min(1).required('Esse campo é obrigatório'),
  expiry_date: yup.string().required('Esse campo é obrigatório'),
});

function TicketRequestDialog({ open, onClose, school }: ITicketRequestDialogProps) {
  const schoolOptions = useApiFormOptions('/escolas/form-options/');
  const defaultValues = {
    school: '',
    qty: '',
    expiry_date: '',
  };

  const resolver = yupResolver(validationSchema);

  const methods = useForm({
    mode: 'onBlur',
    criteriaMode: 'firstError',
    defaultValues,
    resolver,
  });

  useEffect(() => {
    if (!school) return;
    methods.setValue('school', school);
  }, [methods, school]);

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = async (formData: ITicketRequestFormData) => {
    try {
      const date = new Date(formData.expiry_date);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const expiryDate = `${year}-${month}-${day}`;
      const postData = { ...formData, expiry_date: expiryDate };
      await api.post('/solicitacoes/', postData);
      handleClose();
    } catch (err: any) {
      const error = err.response.data;

      if (!error) return;

      const fieldErrors = error as ITicketRequestFormError;

      Object.entries(fieldErrors).forEach(([field, value]) => {
        /* eslint-disable indent */
        switch (field) {
          case 'school':
          case 'qty':
          case 'expiry_date':
            methods.setError(field, { message: value.join('. ') });
            break;
          default:
            break;
        }
        /* eslint-enable indent */
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <FormProvider {...methods} /* eslint-disable-line */>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle>Criar solicitação de ingressos</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Preencha as informações abaixo para liberar um lote de ingressos:
            </DialogContentText>
            <Grid container rowGap={1} mt={2}>
              <Grid item xs={12}>
                <InputSelect
                  name="school"
                  label="Escola"
                  options={schoolOptions}
                  disabled={Boolean(school)}
                />
              </Grid>
              <Grid item xs={12}>
                <InputText name="qty" label="Quantidade" type="number" />
              </Grid>
              <Grid item xs={12}>
                <InputDate name="expiry_date" label="Validade" />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="error">Fechar</Button>
            <Button variant="contained" color="primary" type="submit">Criar</Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
}

TicketRequestDialog.defaultProps = defaultProps;

export default TicketRequestDialog;
