import { forwardRef, ElementType } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';

interface ListItemLinkProps {
  label: string;
  Icon: ElementType;
  to: string;
}

type LinkRefProps = Omit<RouterLinkProps, 'to'>;

const renderLink = (to: string) => forwardRef<HTMLAnchorElement, LinkRefProps>((
  itemProps,
  ref,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />);

function ListItemLink(props: ListItemLinkProps) {
  const { label, Icon, to } = props;

  return (
    <li>
      <ListItem button component={renderLink(to)}>
        <ListItemIcon><Icon /></ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </li>
  );
}

export default ListItemLink;
