import {
  PropsWithChildren, useContext, useEffect,
} from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import axiosInstance from '@valedamusica/api';
import { AuthContext } from '@valedamusica/providers/AuthProvider';

function AxiosInterceptor({ children }: PropsWithChildren) {
  const { auth, logout } = useContext(AuthContext);

  useEffect(() => {
    const requestInterceptor = (config: AxiosRequestConfig) => {
      if (auth?.token === undefined) return config;

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Token ${auth.token}`,
        },
      };
    };

    const errorInterceptor = (err: AxiosError) => {
      if (err.response?.status === 401) {
        logout();
      }
      return Promise.reject(err);
    };

    const requestInterceptors = axiosInstance.interceptors.request.use(requestInterceptor);

    const responseInterceptors = axiosInstance.interceptors.response.use(
      (response) => response,
      errorInterceptor,
    );

    return () => {
      axiosInstance.interceptors.response.eject(responseInterceptors);
      axiosInstance.interceptors.request.eject(requestInterceptors);
    };
  }, [auth]);

  return <div>{children}</div>;
}

export default AxiosInterceptor;
