/* eslint-disable import/prefer-default-export */

export function formatDate(date: string): string {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const day = dateObj.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function convertStringToDate(date: string): Date {
  const [year, month, day] = date.split('-').map((n) => Number(n));
  return new Date(year, month - 1, day);
}
