import { useContext } from 'react';
import Box from '@mui/material/Box';
import MUIDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';

import DrawerLink from '@valedamusica/components/DrawerLink';
import { AuthContext } from '@valedamusica/providers/AuthProvider';

const menuOptions = [
  {
    label: 'Instituições',
    Icon: BusinessIcon,
    to: '/instituicoes',
    staffOnly: true,
  },
  {
    label: 'Solicitações',
    Icon: AssignmentIcon,
    to: '/solicitacoes',
    staffOnly: false,
  },
  {
    label: 'Ingressos',
    Icon: ConfirmationNumberIcon,
    to: '/ingressos',
    staffOnly: false,
  },
  {
    label: 'Relatório de Ingressos',
    Icon: AssessmentIcon,
    to: '/relatorios',
    staffOnly: true,
  },
];

function Drawer() {
  const { user } = useContext(AuthContext);
  const drawerWidth = 300;

  const options = user?.is_staff ? menuOptions : menuOptions.filter(({ staffOnly }) => !staffOnly);

  return (
    <MUIDrawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          {options.map(({ label, Icon, to }) => (
            <DrawerLink key={to} label={label} Icon={Icon} to={to} />
          ))}
        </List>
      </Box>
    </MUIDrawer>
  );
}

export default Drawer;
