import { useContext, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router, Navigate, Route, Routes, useLocation,
} from 'react-router-dom';

import AxiosInterceptor from '@valedamusica/api/AxiosInterceptor';
import Home from '@valedamusica/pages/Home';
import Login from '@valedamusica/pages/Login';
import NotFound from '@valedamusica/pages/NotFound';
import PasswordRecovery from '@valedamusica/pages/PasswordRecovery';
import Summary from '@valedamusica/pages/Summary';

import RequestPassword from '@valedamusica/pages/RequestPassword';
import SchoolList from '@valedamusica/pages/schools/SchoolList';
import TicketRequestList from '@valedamusica/pages/ticketrequests/TicketRequestList';
import TicketList from '@valedamusica/pages/tickets/TicketList';
import { AuthContext } from '@valedamusica/providers/AuthProvider';

const allowedRoutes = ['/novo-acesso', '/recuperar-senha', '/esqueci-minha-senha'];

function RouteContainer({ children, title }: PropsWithChildren<{ title: string }>) {
  const location = useLocation();
  const { auth } = useContext(AuthContext);

  // TODO: no permissions route redirect (check user.is_staff against route.is_staff)

  if (allowedRoutes.some((route) => location.pathname.includes(route))) {
    const pageTitle = `${title} | Vale da Música`;

    return (
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        {children}
      </>
    );
  }

  if (location.pathname !== '/login' && !auth) {
    return <Navigate to="/login" replace />;
  }

  const pageTitle = `${title} | Vale da Música`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <AxiosInterceptor>
        {children}
      </AxiosInterceptor>
    </>
  );
}

function AppRoutes() {
  return (

    <Router>
      <Routes>
        <Route
          path="/login"
          element={(
            <RouteContainer title="Login">
              <Login />
            </RouteContainer>
          )}
        />
        <Route
          path="/"
          element={(
            <RouteContainer title="Home">
              <Home />
            </RouteContainer>
          )}
        />
        <Route
          path="/instituicoes"
          element={(
            <RouteContainer title="Listar Instituições">
              <SchoolList />
            </RouteContainer>
          )}
        />
        <Route
          path="/solicitacoes"
          element={(
            <RouteContainer title="Solicitações">
              <TicketRequestList />
            </RouteContainer>
          )}
        />
        <Route
          path="/solicitacoes/:school"
          element={(
            <RouteContainer title="Solicitações">
              <TicketRequestList />
            </RouteContainer>
          )}
        />
        <Route
          path="/ingressos"
          element={(
            <RouteContainer title="Ingressos">
              <TicketList />
            </RouteContainer>
          )}
        />
        <Route
          path="/ingressos/:ticketRequest"
          element={(
            <RouteContainer title="Ingressos">
              <TicketList />
            </RouteContainer>
          )}
        />
        <Route
          path="/relatorios"
          element={(
            <RouteContainer title="Relatórios">
              <Summary />
            </RouteContainer>
          )}
        />
        <Route
          path="/novo-acesso/:id"
          element={(
            <RouteContainer title="Novo Acesso">
              <PasswordRecovery
                title="Primeiro Acesso"
                description="Para acessar o sistema, crie uma senha abaixo:"
              />
            </RouteContainer>
          )}
        />
        <Route
          path="/recuperar-senha/:id"
          element={(
            <RouteContainer title="Recuperar Senha">
              <PasswordRecovery
                title="Recuperar Senha"
                description="Altere a sua senha no formulário abaixo:"
              />
            </RouteContainer>
          )}
        />
        <Route
          path="/esqueci-minha-senha/"
          element={(
            <RouteContainer title="Esqueci Minha Senha">
              <RequestPassword
                title="Esqueci Minha Senha"
                description="Preencha seu email abaixo e clique em Enviar. Em alguns minutos, você deve receber um email com o link para alteração de senha:"
              />
            </RouteContainer>
          )}
        />
        <Route
          path="*"
          element={(
            <RouteContainer title="Página Não Encontrada">
              <NotFound />
            </RouteContainer>
          )}
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
