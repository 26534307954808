import { AxiosResponse } from 'axios';
import useSWR from 'swr';

import api from '@valedamusica/api';
import { ISelectOption } from '@valedamusica/types';

function useApiFormOptions(endpoint?: string) {
  const baseOption = { value: '', label: '--- Escolha uma Opção ---' };
  const response = useSWR<AxiosResponse<ISelectOption[]>>(endpoint, api.get);

  if (response.isValidating || !response.data?.data) {
    return [baseOption];
  }

  return [baseOption, ...response.data.data];
}

export default useApiFormOptions;
