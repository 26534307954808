import { SWRConfig } from 'swr';
import AuthProvider from '@valedamusica/providers/AuthProvider';
import ThemeProvider from '@valedamusica/providers/ThemeProvider';
import Routes from '@valedamusica/routes';

function App() {
  return (
    <SWRConfig value={{ revalidateOnFocus: false }}>
      <ThemeProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </SWRConfig>
  );
}

export default App;
