import { PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import Header from '@valedamusica/components/Header';

function Layout({ children }: PropsWithChildren) {
  return (
    <>
      <CssBaseline />
      <Header>
        <Box p={2}>
          {children}
        </Box>
      </Header>
    </>
  );
}

export default Layout;
