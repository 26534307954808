import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DownloadIcon from '@mui/icons-material/Download';
import { GridAlignment, GridRenderCellParams } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';
import format from 'date-fns/format';

import api from '@valedamusica/api';
import Layout from '@valedamusica/components/Layout';
import Table from '@valedamusica/components/Table';
import TicketDialog from '@valedamusica/pages/tickets/TicketDialog';
import TicketDialogBulk from '@valedamusica/pages/tickets/TicketDialogBulk';
import { ITicket, ITicketRequest } from '@valedamusica/types';
import { convertStringToDate } from '@valedamusica/utils';

function TicketList() {
  const [isWaitingDownload, setIsWaitingDownload] = useState(false);
  const [openDialogName, setOpenDialogName] = useState('');
  const params = useParams();
  const linkRef = useRef<HTMLAnchorElement>(null);

  const ticketRequestId = params.ticketRequest;
  const parentEndpoint = params.ticketRequest ? `/solicitacoes/${params.ticketRequest}/` : null;
  let endpoint = '/ingressos/';
  if (params.ticketRequest) {
    endpoint += `?ticket_request=${params.ticketRequest}`;
  }
  const parentResponse = useSWR<AxiosResponse<ITicketRequest>>(parentEndpoint, api.get);
  const response = useSWR<AxiosResponse<ITicket[]>>(endpoint, api.get);

  const closeDialog = () => {
    setOpenDialogName('');
    response.mutate();
  };

  const downloadReport = async () => {
    const link = linkRef.current;
    if (!link) {
      console.log('Não foi possível baixar o relatório');
      return;
    }
    setIsWaitingDownload(true);
    const endpointReport = ticketRequestId
      ? `/ingressos/relatorio/?ticket_request=${ticketRequestId}`
      : '/ingressos/relatorio/';
    const { data: blob } = await api.get(endpointReport, { responseType: 'blob' });
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.download = 'relatorio.xlsx';
    link.click();
    setIsWaitingDownload(false);
  };

  const downloadPdf = async (ticketId: number, expiryDate: string, barcode: string) => {
    const link = linkRef.current;
    if (!link) {
      console.log('Não foi possível baixar o ingresso');
      return;
    }
    setIsWaitingDownload(true);
    const endpointPdf = `/ingressos/${ticketId}/download/`;
    const { data: blob } = await api.get(endpointPdf, { responseType: 'blob' });
    link.href = window.URL.createObjectURL(new Blob([blob]));
    const expiryDateObj = convertStringToDate(expiryDate);
    const date = format(expiryDateObj, 'dd.MM.yyyy');
    link.download = `Vale_da_Musica-${date}-10h00-${barcode}.pdf`;
    link.click();
    setIsWaitingDownload(false);
  };

  const downloadTickets = async () => {
    const link = linkRef.current;
    if (!link) {
      console.log('Não foi possível baixar o arquivo compactado');
      return;
    }
    setIsWaitingDownload(true);
    let endpointZip = '/ingressos/download-lote/';
    if (ticketRequestId) {
      endpointZip += `?ticket_request=${ticketRequestId}`;
    }
    const { data: blob } = await api.get(endpointZip, { responseType: 'blob' });
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.download = `Ingressos_Solicitacao_${ticketRequestId}.zip`;
    link.click();
    setIsWaitingDownload(false);
  };

  const rows = response?.data?.data ?? [];

  const columns = [
    {
      field: 'barcode',
      headerName: 'Código de Barras',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
    },
    {
      field: 'name',
      headerName: 'Nome',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
      valueFormatter: ({ value }: { value?: string }) => (value || 'Sem Identificação'),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
      valueFormatter: ({ value }: { value?: string }) => (value || '-'),
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      flex: 0.7,
      headerAlign: 'center' as GridAlignment,
      align: 'left' as GridAlignment,
      valueFormatter: ({ value }: { value?: string }) => {
        if (!value) return '-';
        const [prefix, phoneNumber] = value.split(' ');
        return `(${prefix}) ${phoneNumber} `;
      },
    },
    {
      field: 'created_at',
      headerName: 'Criada Em',
      flex: 0.6,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      valueFormatter: ({ value }: { value: string }) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'is_used',
      headerName: 'Usado?',
      flex: 0.4,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ value }: GridRenderCellParams) => {
        const IconComponent = value ? CheckCircleOutlineIcon : DoNotDisturbIcon;
        return (<Icon><IconComponent /></Icon>);
      },
    },
    {
      field: 'is_cancelled',
      headerName: 'Cancelado?',
      flex: 0.4,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ value }: GridRenderCellParams) => {
        const IconComponent = value ? CheckCircleOutlineIcon : DoNotDisturbIcon;
        return (
          <Icon>
            <IconComponent />
          </Icon>
        );
      },
    },
    {
      field: 'download',
      headerName: 'Baixar',
      flex: 0.4,
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ row }: GridRenderCellParams<string, ITicket>) => (
        <IconButton
          disabled={isWaitingDownload}
          onClick={() => downloadPdf(row.pk, row.expiry_date, row.barcode)}
        >
          <DownloadIcon />
        </IconButton>
      ),
    },
  ];

  let title = 'Ingressos';
  const requestData = parentResponse.data?.data;
  const schoolData = requestData?.school_details;
  if (params.ticketRequest) {
    title = parentResponse.isValidating
      ? '-'
      : `Ingressos da solicitação #${requestData?.pk || ''}`;
  }

  return (
    <Layout>
      <Grid container rowGap={2} flexDirection="column">
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="center">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            rows={rows}
            columns={columns}
            loading={response.isValidating}
          />
        </Grid>
        <Grid item xs={12} container columnGap={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              onClick={downloadReport}
              color="secondary"
              disabled={!rows.length || isWaitingDownload}
            >
              Baixar Relatório
            </Button>
            <Hidden>
              <a ref={linkRef} /* eslint-disable-line */ />
            </Hidden>
          </Grid>
          <Hidden xsUp={!schoolData?.is_bulk_enabled}>
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                onClick={downloadTickets}
                color="error"
                disabled={isWaitingDownload}
              >
                Baixar Lote
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                size="medium"
                onClick={() => setOpenDialogName('bulk')}
                disabled={isWaitingDownload}
              >
                Emitir Lote
              </Button>
            </Grid>
          </Hidden>
          <Grid item>
            <Button
              variant="contained"
              size="medium"
              onClick={() => setOpenDialogName('single')}
              disabled={isWaitingDownload}
            >
              Emitir Cortesia
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TicketDialog open={openDialogName === 'single'} onClose={closeDialog} request={params.ticketRequest} />
      <TicketDialogBulk open={openDialogName === 'bulk'} onClose={closeDialog} request={params.ticketRequest} />
    </Layout>
  );
}

export default TicketList;
